.navbar {
  width: 100%;
  height: 4rem;
  padding: 0 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .hamMenu-home {
    display: none;
    position: absolute;
    left: 1rem;
    height: 100%;
    align-items: center;
    width: fit-content;

    img {
      width: 20px;
    }
  }

  .logo {
    display: flex;
  }

  .menu-items {
    display: flex;
    gap: 2rem;
    align-items: center;
    font-size: 0.9rem;

    p {
      margin: 0;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .btns-div {
    display: flex;

    .gs-Btn {
      color: white;
      font-weight: 500;
      border-radius: 45px;
      background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
      padding: 0.7rem 1.5rem;
      font-size: 0.8rem;
    }

    div {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.color-box {
  width: 100%;
  height: 1rem;
  background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 0;
    justify-content: center;
    .hamMenu-home {
      display: flex;
    }
    .menu-items {
      display: none;
    }
    .btns-div {
      display: none;
    }
  }
  .color-box {
    height: 0.5rem;
  }
}
