@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.dashboard-sidebar1 {
  height: 100vh;
  overflow-y: scroll;
  width: 20vw;
  background: #ededed5e;
  position: relative;

  .logo-border {
    width: 100%;
    height: 70px;
    padding: 0 2vw 0 2vw;
    border-bottom: 0.5px solid #e5e5e5;
    display: flex;
    align-items: center;
    padding-left: 2vw;

    .dashboard-left {
      width: 100%;
    }
  }

  .each-sidenav {
    border-radius: 5px;
    height: 43px;
    width: calc(90%);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #100f0d;
    cursor: pointer;
    margin: 15px 0;
    padding-left: 0px;
    &:hover {
      transform: scale(1.05);
      transition: all ease 0.2s;
      font-weight: 600;
    }
  }

  .sidebar-line {
    padding-right: 2vw;
    margin: 30px 0px;
    height: 1px;
    width: 100%;
    margin-left: -20px;
  }

  .history-div {
    width: 100%;
    padding: 1rem 1rem 2rem;

    .history-box {
      width: 100%;
      background: white;
      border-radius: 15px;
      color: #100f0d;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 1rem 1rem;
      gap: 2rem;

      .history-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        .font1 {
          font-size: 0.85rem;
        }

        .pathId-text {
          font-size: 0.8rem;
          text-transform: none;
        }
      }

      .open-btn {
        margin-top: 1rem;
        width: 100%;
        padding: 0.75rem 0;
        text-align: center;
        color: white;
        border-radius: 35px;
        font-size: 0.8rem;
        font-weight: 500;
        background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
        cursor: pointer;
        @include btn-animate(#fff);
      }
    }
  }
}

.dashboard-logo {
  width: 14vw;
}

.side-btn {
  cursor: pointer;
  @include btn-animate(#fff);
}
