.listview-container {
  width: 100%;
  height: calc(100% - 3rem);

  .listview-header {
    width: 100%;
    height: 3.5rem;
    display: flex;
    padding: 0 35px;
    border-bottom: 0.5px solid #e5e5e5;

    div {
      width: 20%;
      height: 100%;
      font-size: 0.8rem;
      display: flex;
      align-items: flex-end;
      font-weight: 300;
      padding-bottom: 0.5rem;
    }
  }

  .listview-content {
    width: 100%;
    height: calc(100% - 3.5rem);
    overflow-y: scroll;

    .each-list-content {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 2rem 35px;
      border-bottom: 0.5px solid #e5e5e5;
      font-size: 0.8rem;
      font-weight: 300;
      transition: all ease 0.2s;

      &:hover {
        background: rgba(237, 237, 237, 0.368627451);
      }

      div {
        width: 20%;
      }

      .web-btn-div {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .web-btn {
          width: fit-content;
          border-radius: 35px;
          background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
          color: white;
          padding: 0.5rem 2.5rem;
          font-weight: 500;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }

        .hidden-links-div {
          display: none;
          width: 100%;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem 0;
          align-items: center;

          .each-hidden-link {
            width: 100%;
            text-align: center;
            text-transform: lowercase;
            display: flex;
            justify-content: center;

            p {
              margin: 0;
              width: fit-content;
              cursor: pointer;
              transition: all ease 0.2s;

              &:hover {
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }
  }
}
