.topNav {
    background-color: white;
    border-bottom: 1px solid #f5f5f5;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  
    :nth-child(2) {
      padding-right: 3.3vw;
      display: flex;
  
      div {
        padding: 0px 20px;
        font-size: 13px;
        font-weight: 600;
        color: #100F0D;
  
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
      }
  
      a {
        padding: 0px 20px;
        font-size: 11px;
        font-weight: 700;
        color: white;
        text-decoration: none;
  
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
      }
    }
  }
  
  .goBack {
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    line-height: 1;
    padding-left: 10px;
    position: relative;
  }
  
  .goBack::before,
  .goBack::after {
      content: "";
      display: block;
      left: 0px;
      position: absolute;
      top: 50%;
      transition: left 0.1s;
  }
  .goBack::before {
    border-color: #FFFFFF;
    border-style: solid;
    border-width: 0 0 2px 2px;
    height: 4px;
    margin-top: -3px;
    width: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .goBack::after {
    background: #FFFFFF;
    height: 2px;
    width: 6px;
    margin-top: -1px;
  }
  .goBack:hover::before,
  .goBack:hover::after {
      left: -3px;
  }
  
  .leftMove{
    padding-left: 3.5vw;
    align-items: center;
    display: flex;
  }
  
  .navSelectedSub {
    display: none;
    width: 50%;
    height: 2px;
    background: #84E188;
    position: absolute;
    // bottom: -7px;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    border-bottom: 1px solid #f5f5f5;
    z-index: 15;
    background: white;
    position: fixed;
    // top: 33px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 3.5vw;
  
    .leftSide {
      // background-color: pink;
      display: flex;
      padding: 15px 0px;
  
      // padding-left: 90px;
      .logo{
        padding-right: 20px;
        transition: all ease-in 0.2s;
  
        &:hover {
          scale: 1.05;
        }
      }

      .dropbox{
        height: 100%;
        display: flex;
        align-items: center;
        .line{
          margin: 0px 20px;
          border: 2px solid #E5E5E5;
          height: 100%;
        }
        .dropDown{
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all ease-in .2s;
          &:hover{
            transform: scale(1.2);
          }
        }
        .dropDownText1{
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #100F0D;
        }
        .dropDownText2{
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: #100F0D;
        }
        .dropDownArrowbox{
          transition: all ease-in .2s;
        }

      }
    }
  
    .rightSide {
      // padding-right: 90px;
      display: flex;
      align-items: center;
      justify-content: end;
      color: #999999;
      // gap: 0.25rem;
  
      &>div {
        color: #100F0D;
        // position: relative;
        // width: 100%;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        font-size: calc(8px + 0.5vw);
        font-weight: 400;
  
        &:hover {
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
      }
  
      &> :last-child {
        // height: 43px;
        height: 60%;
        width: max-content;
      }
    }

    .button1{
      background: linear-gradient(90deg, #59A2DD 4.23%, #186AB4 100%);
      border-radius: 35px;
      height: 43px !important;
      margin-right: 20px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      color: white;
      font-weight: 800;
      // width: 170px !important;
      font-weight: 600 !important;
    }
  
    .button {
      // background: linear-gradient(90deg, #59A2DD 4.23%, #186AB4 100%);
      background: #1F304F;
      border-radius: 35px;
      height: 43px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      color: white;
      font-weight: 800;
      // width: 170px !important;
      font-weight: 600 !important;
    }
  }
  
  .mobileContainer {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      display: none;
    }
  
    .topNav {
      display: none;
    }
  
    .mobileContainer {
      display: flex;
      justify-content: space-between;
      width: 100vw;
      border-bottom: 1px solid #E5E5E5;
      padding: 15px;
      position: fixed;
      top: 0;
      background-color: #FFFFFF;
  
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    // .container{
    //     grid-template-columns: 1fr;
    //     .leftSide{
    //         padding-left: 20px;
    //     }
    //     .rightSide{
    //         display: none;
    //         // padding-right: 20px;
    //     }
    // }
    .title {
      display: none;
    }

    .moblogos{
      width: 40vw;
    }
    .dropbox{
      height: 100%;
      display: flex;
      align-items: center;
      .line{
        margin: 0px 20px;
        border: 2px solid #E5E5E5;
        height: 100%;
      }
      .dropDown{
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all ease-in .2s;
        &:hover{
          transform: scale(1.2);
        }
      }
      .dropDownText1{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #100F0D;
      }
      .dropDownText2{
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #100F0D;
      }
      .dropDownArrowbox{
        transition: all ease-in .2s;
      }

    }
  }